$base-font-size: 12px;
$base-line-height: 1.4;
$color-text: #2e2d2f;
$color-text-lighter: #dadadc;
$color-text-light: #ffffff;
$orange: #EC662E;

$selection: #EC662E;
$highlight: #fff;

$h1-size: 4.6em;
$h2-size: 3.5em;
$h3-size: 2.8em;
$h4-size: 2.1em;
$h5-size: 1.7em;
$h6-size: 1.4em;

$link: $color-text;
$link-hover: $color-text;

$big-text-size: 39px;
$big-text-size-mobile: 30px;
$smaller-font-size-break: 1400px;