#preloader {
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color: #fff;
  z-index:9999;
  .loader-wrapper{
    display: table;
    width: 100%;
    height: 100%;
    .loader-wrapper-inner{
      display: table-cell;
      vertical-align: middle;
      .loader {
        height:40px;
        width:40px;
        margin:15px auto;
        position:relative;
        border-left:1px solid #fff;
        border-right:1px solid $orange;
        border-bottom:1px solid $orange;
        border-top:1px solid $orange;
        border-radius:100%;
        -webkit-animation: rotation .6s infinite linear;
        -moz-animation: rotation .6s infinite linear;
        -o-animation: rotation .6s infinite linear;
        animation: rotation .6s infinite linear;
      }
    }
  }
}

@-webkit-keyframes rotation {
  from {-webkit-transform: rotate(0deg);}
  to {-webkit-transform: rotate(359deg);}
}

@-moz-keyframes rotation {
  from {-moz-transform: rotate(0deg);}
  to {-moz-transform: rotate(359deg);}
}

@-o-keyframes rotation {
  from {-o-transform: rotate(0deg);}
  to {-o-transform: rotate(359deg);}
}

@keyframes rotation {
  from {transform: rotate(0deg);}
  to {transform: rotate(359deg);}
}