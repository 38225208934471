#navigation {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  .navigation-inner {
    width: 100%;
    max-width: 1600px;
    padding: 0 20px;
    margin: 46px auto 0 auto;
    #menu-switch {
      position: absolute;
      left: 44%;
      top: 53px;
      width: 60px;
      height: 32px;
      z-index: 10;
      @media all and (min-width: 1321px) {
        display: none;
      }
      @media all and (max-width: 1100px) {
        left: 25px;
      }
      .wrapper {
        display: table;
        width: 100%;
        height: 100%;
        .wrapper-inner {
          display: table-cell;
          width: 100%;
          height: 100%;
          vertical-align: middle;
          .switch {
            display: block;
            cursor: pointer;
            width: 40px;
            height: 32px;
            position: relative;
            margin: 0 auto;
            &:focus {
              outline: none;
            }
            .line {
              content: "";
              position: absolute;
              display: block;
              width: 40px;
              height: 4px;
              background-color: #fff;
              cursor: pointer;
              transition: all .2s ease-in-out;
              z-index: -1;
            }
            .line:nth-child(1) {
              top: 0;
            }
            .line:nth-child(2) {
              top: 10px;
            }
            .line:nth-child(3) {
              top: 20px;
            }
            .sidr-open & {
              .line {
                background-color: transparent;
              }
              .line:nth-child(1),
              .line:nth-child(3) {
                top: 12px;
                height: 4px;
                background-color: #fff;
              }
              .line:nth-child(1) {
                transform: rotate(45deg);
              }
              .line:nth-child(3) {
                transform: rotate(-45deg);
              }
            }
          }
        }
      }
    }
    .logo {
      float: right;
      margin-left: 4%;
      display: block;
      @media all and (max-width: 1450px) {
        max-width: 200px;
        margin-left: 2%;
      }
    }
    .logo-color {
      float: right;
      margin-left: 4%;
      display: none;
      @media all and (max-width: 1450px) {
        max-width: 200px;
        margin-left: 2%;
      }
    }
    .menu {
      float: right;
      padding: 0;
      margin: 0;
      margin-top: 15px;
      @media all and (max-width: 1450px) {
        margin-top: 10px;
      }
      @media all and (max-width: 1320px) {
        display: none;
      }
      li {
        display: inline-block;
        list-style-type: none;
        list-style-image: none;
        a {
          color: $color-text-light;
          @extend %font-regular;
          text-decoration: none;
          font-size: 15.4px;
          position: relative;
          margin: 0 15px;
          letter-spacing: 0;
          @media all and (max-width: 1700px) {
            margin: 0 8px;
            font-size: 14px;
          }
          &:after {
            position: absolute;
            left: 0;
            bottom: -5px;
            width: 0;
            height: 1px;
            background-color: #fff;
            content: '';
            opacity: 0;
            transition: all .3s ease-in-out;
          }
          &.active,
          &:hover {
            &:after {
              opacity: 1;
              width: 36px;
            }
          }
        }
      }
    }
  }
}

.sticky {
  #navigation {
    position: fixed;
    background-color: rgba(255,255,255,.9);
    box-shadow: -2px 1px 9px 0px rgba(0, 0, 0, 0.2);
    z-index: 10;
    .navigation-inner {
      margin-top: 16px;
      #menu-switch {
        top: 22px;
        .wrapper {
          .wrapper-inner {
            .switch {
              .line {
                background-color: #E45300;
              }
            }
          }
        }
      }
      .logo {
        display: none;
      }
      .logo-color {
        display: block;
        margin-bottom: 18px;
      }
      .menu {
        margin-top: 15px;
        li {
          a {
            color: $orange;
            &:after {
              background-color: $orange;
            }
          }
        }
      }
    }
  }
}

.sticky.sidr-open {
  #navigation {
    .navigation-inner {
      #menu-switch {
        .wrapper {
          .wrapper-inner {
            .switch {
              .line {
                background-color: transparent;
              }
              .line:nth-child(1),
              .line:nth-child(3) {
                background-color: #e45300;
              }
            }
          }
        }
      }
    }
  }
}

#sidr {
  background: #E45300;
  box-shadow: -2px 1px 9px 0px rgba(0, 0, 0, 0.2);
  z-index: 20;
  .sidr-inner{
    padding: 40px 20px;
    li{
      list-style-type: none;
      list-style-image: none;
      a{
        color: #fff;
        @extend %font-regular;
        text-decoration: none;
        font-size: 17px;
        line-height: 20px;
        position: relative;
        margin: 0 15px;
        letter-spacing: 0;
        box-shadow: none;
        padding-bottom: 20px;
        &:after {
          position: absolute;
          left: 15px;
          bottom: 12px;
          width: 0;
          height: 1px;
          background-color: #fff;
          content: '';
          opacity: 0;
          transition: all .3s ease-in-out;
        }
        &:hover{
          box-shadow: none;
          border: none;
          &:after {
            opacity: 1;
            width: 36px;
          }
        }
      }
    }
  }
}