#header{
  margin-bottom: 35px;
  img.big{
    @media all and (max-width: 1100px) {
      display: none;
    }
  }
  img.small{
    @media all and (min-width: 1101px) {
      display: none;
    }
  }
}

#short-presentation{
  background: url(../images/arrows-grey.png) no-repeat right bottom;
  .section-inner{
    max-width: 1600px;
    padding: 0 20px 100px 20px;
    margin: 0 auto;
  }
  .section-left{
    width: 83%;
    float: left;
    @media all and (max-width: 1640px) {
      width: 80%;
    }
    @media all and (max-width: 1250px) {
      width: 75%;
    }
    @media all and (max-width: 910px) {
      float: none;
      width: 100%;
    }
  }
  .section-right{
    width: 17%;
    float: left;
    padding-left: 3%;
    @media all and (max-width: 1250px) {
      width: 25%;
    }
    @media all and (max-width: 910px) {
      float: none;
      width: 100%;
      padding-left: 0;
      margin-top: 40px;
    }
    h2 {
      margin-top: 0;
      font-size: $big-text-size;
      @media all and (max-width: $smaller-font-size-break) {
        font-size: $big-text-size-mobile;
      }
    }
    p{
      text-align: justify;
      font-size: 14px;
      max-width: 225px;
      @media all and (max-width: 910px) {
        max-width: none;
      }
    }
  }
}

#company-profile{
  max-width: 1600px;
  padding: 0 20px;
  margin: 0 auto;
  .owl-dots{
    margin-top: 10px;
    margin-bottom: 16px;
    .owl-dot{
      position: relative;
    }
  }
  .download{
    text-align: center;
    margin-bottom: 130px;
    @media all and (max-width: 1110px) {
      margin-bottom: 40px;
    }
    a{
      background: url(../images/download.png);
      width: 195px;
      height: 35px;
      background-size: contain;
      display: inline-block;
      text-indent: -9999px;
      overflow: hidden;
    }
  }
}

#our-clients{
  position: relative;
  margin-bottom: 80px;
  .section-inner{
    max-width: 1600px;
    padding: 0 20px;
    margin: 0 auto;
    .overlay{
      background-color: #E9EAEA;
      width: 30%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      @media all and (max-width: 1110px) {
        display: none;
      }
      &:after {
        content: '';
        right: -28px;
        bottom: -10px;
        background: url(../images/arrows-orange-small.png) no-repeat center center;
        width: 75px;
        height: 53px;
        position: absolute;
      }
    }
  }
  .section-content-left{
    position: relative;
    z-index: 1;
    width: 30.46875%;
    float: left;
    margin-top: 40px;
    margin-bottom: 60px;
    @media all and (max-width: 1600px) {
      padding-left: 40px;
    }
    @media all and (max-width: 1110px) {
      float: none;
      width: 100%;
    }
    @media all and (max-width: 740px) {
      padding-left: 0;
    }
    h2 {
      font-size: $big-text-size;
      @media all and (max-width: $smaller-font-size-break) {
        font-size: $big-text-size-mobile;
      }
    }
    p{
      font-size: 14px;
      max-width: 225px;
      text-align: justify;
      @media all and (max-width: 1110px) {
        max-width: 600px;
      }
      @media all and (max-width: 640px) {
        max-width: none;
      }
    }
  }
  .section-content-right{
    width: 69.53125%;
    float: left;
    @media all and (max-width: 1110px) {
      width: 100%;
      float: none;
    }
    .logos {
      .group{
        float: left;
        width: 100%;
        text-align: center;
        .logo{
          display: inline-block;
          vertical-align: top;
          width: 25%;
          @media all and (max-width: 920px) {
            width: auto;
            min-width: 220px;
          }
        }
      }
    }
  }
}

#case-studies{
  .section-title{
    max-width: 1600px;
    padding: 0 20px;
    margin: 0 auto;
    h2 {
      font-weight: normal;
      margin-bottom: 10px;
      font-size: $big-text-size;
      @media all and (max-width: $smaller-font-size-break) {
        font-size: $big-text-size-mobile;
      }
    }
  }
  .section-gallery {
    .owl-prev{
      padding: 0;
      margin-left: 9%;
      @media all and (max-width: 640px) {
        display: none;
      }
      span{
        background: url(../images/arrow-wbg-left.png);
        width: 54px;
        height: 54px;
      }
    }
    .owl-next{
      padding: 0;
      margin-right: 9%;
      @media all and (max-width: 640px) {
        display: none;
      }
      span{
        background: url(../images/arrow-wbg-right.png);
        width: 54px;
        height: 54px;
      }
    }
    .owl-dots{
      margin-top: -40px;
      @media all and (max-width: 640px) {
        margin-top: 0;
        margin-bottom: 40px;
      }
      .owl-dot{
        position: relative;
      }
    }
  }
  .section-content{
    .box{
      float: left;
      width: 50%;
      padding: 20px;
      @media all and (max-width: 1110px) {
        float: none;
        width: 100%;
      }
      &:nth-child(4n+1),
      &:nth-child(4n+4) {
        background-color: #E9EAEA;
        @media all and (max-width: 1110px) {
          background-color: #fff;
        }
      }
      &:nth-child(4n+1),
      &:nth-child(4n+3) {
        @media all and (max-width: 1110px) {
          background-color: #e9eaea;
        }
      }
      &:nth-child(4n+1){
        .content{
          margin-top: 90px;
          margin-bottom: 70px;
          @media all and (max-width: 1110px) {
            margin-top: 50px;
            margin-bottom: 50px;
          }
        }
        .title{
          @media all and (max-width: 1540px) {
            margin-top: -20px;
          }
          @media all and (max-width: $smaller-font-size-break) {
            margin-top: 0;
          }
        }
      }
      &:nth-child(4n+2){
        .content{
          max-width: 800px;
          margin-top: 90px;
          margin-bottom: 70px;
          @media all and (max-width: 1110px) {
            margin-top: 50px;
            margin-bottom: 50px;
            max-width: none;
          }
        }
        .title{
          @media all and (max-width: 1540px) {
            margin-top: -20px;
          }
          @media all and (max-width: $smaller-font-size-break) {
            margin-top: 0;
          }
        }
      }
      &:nth-child(4n+3){
        .content{
          max-width: 800px;
          margin-top: 30px;
          @media all and (max-width: 1110px) {
            margin-top: 50px;
            margin-bottom: 50px;
            max-width: none;
          }
        }
        .title{
          margin-top: -20px;
          @media all and (max-width: $smaller-font-size-break) {
            margin-top: 0;
          }
        }
      }
      &:nth-child(4n+4){
        .content{
          max-width: 800px;
          margin-top: 30px;
          @media all and (max-width: 1110px) {
            margin-top: 50px;
            margin-bottom: 50px;
            max-width: none;
          }
        }
        .title{
          margin-top: -20px;
          @media all and (max-width: $smaller-font-size-break) {
            margin-top: 0;
          }
        }
      }
      .content{
        padding-left: 14%;
        @media all and (max-width: $smaller-font-size-break) {
          padding-left: 10%;
          padding-right: 10%;
        }
      }
      .counter{
        float: left;
        font-size: 160px;
        @extend %font-bold;
        color: #D6D7D8;
        line-height: .7;
        margin-right: 3%;
        margin-bottom: 20px;
        @media all and (max-width: 600px) {
          font-size: 80px;
        }
      }
      .title{
        float: left;
        margin-top: 0;
        font-weight: 400;
        max-width: calc(100% - 110px);
        font-size: $big-text-size;
        @media all and (max-width: $smaller-font-size-break) {
          font-size: $big-text-size-mobile;
          max-width: calc(100% - 120px);
        }
        @media all and (max-width: 600px) {
          font-size: 25px;
          max-width: calc(100% - 60px);
        }
      }
      ul{
        clear: both;
        list-style-type: none;
        list-style-image: none;
        padding-left: 15px;
        li{
          list-style-type: none;
          list-style-image: none;
          padding-left: 20px;
          position: relative;
          font-size: 14px;
          margin-bottom: 5px;
          &:before{
            position: absolute;
            left: 0;
            top: 5px;
            width: 8px;
            height: 8px;
            background-color: $orange;
            content: '';
          }
        }
      }
    }
  }
}

#contact {
  position: relative;
  &:after {
    content: '';
    top: -120px;
    right: 80px;
    background: url(../images/arrows-orange.png) no-repeat center center;
    background-size: contain;
    width: 318px;
    height: 226px;
    position: absolute;
    @media all and (max-width: 910px) {
      display: none;
    }
  }
  .section-inner{
    max-width: 1600px;
    padding: 0 20px 110px 20px;
    margin: 0 auto;
    @media all and (max-width: 1150px) {
      padding-bottom: 0;
    }
  }
  .section-title{
    margin-bottom: 70px;
    h2{
      font-weight: 700;
      margin-bottom: 0;
      line-height: 1;
      font-size: $big-text-size;
      @media all and (max-width: $smaller-font-size-break) {
        font-size: $big-text-size-mobile;
      }
    }
    p{
      font-size: 14px;
    }
  }
  .section-content{
    .box{
      min-height: 473px;
      width: calc(33.33% - 40px);
      margin-right: 40px;
      float: left;
      vertical-align: top;
      border: solid 1px #d9dadb;
      text-align: center;
      position: relative;
      @media all and (max-width: 1150px) {
        width: 100%;
        float: none;
        max-width: 500px;
        min-height: 350px;
        margin: 0 auto 50px auto;
      }
      .content{
        position: absolute;
        top: 50%;
        padding: 0 20px;
        width: 100%;
        transform: translate(0, -50%);
      }
      h3.title{
        white-space: nowrap;
        font-size: $big-text-size;
        @media all and (max-width: $smaller-font-size-break) {
          font-size: $big-text-size-mobile;
        }
        @media all and (max-width: 400px) {
          font-size: 28px;
        }
      }
      .subtitle{
        font-size: 17px;
        @media all and (max-width: 1410px) {
          font-size: 15px;
        }
        &:after{
          content: '';
          display: block;
          width: 28px;
          height: 2px;
          margin: 23px auto;
          background-color: $orange;

        }
      }
      p{
        font-size: 17px;
        @media all and (max-width: 1410px) {
          font-size: 15px;
        }
      }
    }
  }
}