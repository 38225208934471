body{
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
  background-color:#fff;
  overflow-x: hidden !important;
}

a{
  color: $color-text;
  text-decoration: none;
}

*{
  outline: none;
}

img{
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
}

strong {
  @extend %font-bold;
}

h1, h2, h3, h4 {
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 30px;
}

.section {
  clear: both;
  @include clearfix;
  .section-inner{
    @include clearfix;
  }
}

// RWD YT

.video-responsive{
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
}
.video-responsive iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}


// Owl

.owl-loaded{
  position: relative;
  .owl-stage-outer {
    overflow: hidden;
    max-width: 100%;
    .owl-item {
      float: left;
    }
  }
  .owl-nav{
    position: absolute;
    top: 50%;
    margin-top: -27px;
    left: 0;
    width: 100%;
    &.disabled{
      display: none;
    }
    .owl-prev{
      float: left;
      background: none;
      border: none;
      padding: 0;
      &.disabled{
        display: none;
      }
      span{
        display: inline-block;
        background: url(../images/arrow-wbg-left.png);
        width: 54px;
        height: 54px;
      }
    }
    .owl-next{
      float: right;
      background: none;
      border: none;
      padding: 0;
      &.disabled{
        display: none;
      }
      span{
        display: inline-block;
        background: url(../images/arrow-wbg-right.png);
        width: 54px;
        height: 54px;
      }
    }
  }
  .owl-dots{
    text-align: center;
    .owl-dot{
      width: 16px;
      height: 16px;
      padding: 0;
      margin: 7px;
      border: solid 1px #d7d8d9;
      border-radius: 100%;
      display: inline-block;
      &.active {
        position: relative;
        span {
          position: absolute;
          top: 3px;
          left: 3px;
          display: inline-block;
          width: 8px;
          height: 8px;
          background-color: $orange;
          border-radius: 100%;
        }
      }
    }
  }
}