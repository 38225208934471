// ==================================================
// Basic Styles
// ==================================================
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

::-moz-selection {
	background: $selection;
	color: #fff;
	text-shadow: none;
}

::selection {
	background: $selection;
	color: #fff;
	text-shadow: none;
}

body {
	@extend %font-regular;
	font-size: $base-font-size;
	line-height: $base-line-height;
	color: $color-text;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
  max-width: 1920px;
  margin: 0 auto;
}
