footer{
  position: relative;
  overflow: hidden;
  background-color: $orange;
  clear: both;
  .footer-inner{
    p{
      padding: 0 20px;
      color: $color-text-light;
      margin: 12px 0;
      font-size: 14px;
      text-align: center;
      @extend %font-bold;
      @media all and (max-width: 1400px) {
        font-weight: normal;
      }
      a{
        color: $color-text-light;
      }
    }
  }
}