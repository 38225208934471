@import url('//fonts.googleapis.com/css?family=Asap:700,400');

$font-family: 'Asap';


%font-bold {
  font-family: $font-family, Calibri, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
}

%font-regular {
  font-family: $font-family, Calibri, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
}

%font-light {
  font-family: $font-family, Calibri, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
}